import withNotification from "@/mixins/withNotification"

export default {
  mixins: [withNotification],

  methods: {
    showResendNotification(handler) {
      this.$notify({ title: this.$t("alerts.import_before_resend"), handler })
    }
  }
}
