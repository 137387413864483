<template lang="pug">
    .resend-inventory 
      .otas
        .otas__title  {{ getText('ota_label') }}
        .otas__buttons
          AppButton(
            name="selectAll"
            :title="getText('select_all')"
            @click="selectAll"
          )
          AppButton(
            name="clear"
            :title="getText('clear')"
            @click="clearSelection"
          )
        .otas__list
          .ota(
            v-for="ota in otaList"
            :key="ota.id"
            :class="{ 'ota__selected': filters.otaStates[ota.id] }"
          )
            AppCheckbox(
              :value="filters.otaStates[ota.id]"
              @change="changeOtaState(ota.id)"
              :disabled="isOtaDisabled(ota.id)"
            )
            .ota__name {{ ota.name }}

      .filters
        p.filter-title {{ getText('period') }}
        .datepicker-wrapper
          AppDatepicker(
            :value="filters.dates"
            :range="true"
            :disabled-date="disabledDate"
            :clearable="false"
            @change="changeDates"
          )
        p.filter-title {{ getText(referenceType) }}
        AppDropdown.reference(
          allow-empty
          close-on-select
          searchable
          :value="filters.reference"
          :items="references"
          @select="handleSelect('reference', $event)"
          @remove="handleRemove('reference', $event)"
          order-direction="keep"
        )
        
        .car-class-label 
          p.filter-title {{ getText('car_class') }}
          .constraint
            FaIcon(
              icon="exclamation-triangle"
            )
            p.filter-title {{ getText('car_class_constraint') }}
        AppDropdown.car-class(
          v-if="isCarClassesDisabled"
          :disabled="true"
          :title="$t(`inventory_management.select_${absentField}_first`)"
        )
        AppDropdown.car-class(
          v-else
          checkbox
          batch-select
          multiple
          allow-empty
          close-on-select
          searchable
          :loading="carClassesLoading"
          value-key="id"
          title-key="name"
          :placeholder="$t('inventory_management.select_car_classes')"
          :value="filters.carClasses"
          :items="carClassesWithSuffix"
          order-direction="preorder"
          :is-item-disabled="isItemDisabled"
          :max-select-count="maxSelectCountCarClass"
          @select="handleSelect('carClasses', $event)"
        )

        .car-class-tooltip(:class="{ 'car-class-tooltip_visible': showTooltip }")
          FaIcon(
            icon="exclamation-triangle"
          )
          span {{ getText('tooltip', {count: unselectedCarClasses}) }}

        .buttons 
          AppButton(
            name="cancel"
            :title="getText('cancel')"
            @click="cancel"
          )
          AppButton(
            name="update"
            :disabled="isUpdateDisabled"
            :title="getText('update')"
            @click="update"
          )
            template(v-slot:before)
              .update-tooltip-container(
                v-if="hasInProgressExports"
                v-b-tooltip.hover.topleft="{ title: getText(`export_is_active_for_${referenceType}`), customClass: 'update-tooltip'}"
              )


</template>

<script>
  // store modules
  import sharedCarClassModule from "@/config/store/shared_inventory_management/car_classes"
  import shopCarClassModule from "@/config/store/matching/car_class"
  import exportsModule from "@/config/store/inventory_exports"
  import otaAccountsModule from "@/config/store/ota_accounts"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { isEmpty, reduce, each, some, values, intersection, filter, find, flatMap } from "lodash-es"
  import { format } from "date-fns"
  import { DATE_FNS_DATE_FORMAT } from "@/config/constants"
  import { SERIALIZER_VIEW_RESEND_MODAL } from "@/config/constants"

  import { startOfCurrentDateInTimeZone } from "@/helpers/dates"
  import { mapGetters } from "vuex"

  const sharedCarClassMixin = withStoreModule(sharedCarClassModule, {
    name: "sharedCarClass",
    readers: { sharedCarClassesLoading: "loading", sharedCarClasses: "items" },
    actions: { fetchSharedCarClasses: "FETCH_ITEMS" }
  })

  const shopCarClassMixin = withStoreModule(shopCarClassModule, {
    name: "shopCarClass",
    readers: { shopCarClassesLoading: "loading", shopCarClasses: "items" },
    actions: { fetchShopCarClasses: "FETCH_ITEMS" }
  })

  const exportsMixin = withStoreModule(exportsModule, {
    name: "inventoryExports",
    readers: { exports: "items" }
  })

  const otaAccountsStoreMixin = withStoreModule(otaAccountsModule, {
    name: "otaAccounts",
    readers: {
      otaAccounts: "items"
    },
    actions: {
      fetchOtaAccounts: "FETCH_ITEMS"
    }
  })

  export default {
    mixins: [sharedCarClassMixin, shopCarClassMixin, exportsMixin, otaAccountsStoreMixin],

    props: {
      referenceType: String,
      otaList: Array,
      references: Array,
      processingReferenceIds: {
        type: Array,
        default: () => []
      },
      resendAction: {
        type: Function,
        default: () => {}
      }
    },

    components: {
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppDatepicker: () => import("@/components/elements/AppDatepicker")
    },

    data() {
      const otaStates = reduce(
        this.otaList,
        (otas, ota) => {
          otas[ota.id] = false
          return otas
        },
        {}
      )

      return {
        filters: {
          otaStates,
          reference: {},
          carClasses: [],
          dates: []
        },
        unselectedCarClasses: 0,
        showTooltip: false,
        timerId: null
      }
    },

    beforeUnmount() {
      if (this.timerId) {
        window.clearInterval(this.timerId)
      }
    },

    mounted() {
      this.fetchOtaAccounts()
    },

    watch: {
      chosenOtaIds: async function(newValue, oldValue) {
        if (oldValue.length !== newValue.length) {
          if (!this.isCarClassesDisabled) {
            await this.fetchCarClasses()
          }

          const carClassesForChosenOtaIds = filter(
            this.filters.carClasses,
            carClass => intersection(carClass?.immutable_ota_ids || [], newValue).length > 0
          )
          if (carClassesForChosenOtaIds.length !== this.filters.carClasses.length) {
            this.unselectedCarClasses = Math.abs(carClassesForChosenOtaIds.length - this.filters.carClasses.length)
            this.timerId = window.setTimeout(this.hideTooltip, 5000)
            this.showTooltip = true
            this.filters.carClasses = carClassesForChosenOtaIds
          }
        }
      },

      "filters.reference": function() {
        if (!this.isCarClassesDisabled) {
          this.fetchCarClasses()
          this.filters.carClasses = []
        }
      },
      "filters.dates": async function() {
        if (!this.isCarClassesDisabled) {
          await this.fetchCarClasses()
          const activeCarClasses = filter(
            this.filters.carClasses,
            carClass => !this.isItemDisabled(find(this.carClasses, { id: carClass.id }))
          )
          if (activeCarClasses.length !== this.filters.carClasses.length) {
            this.unselectedCarClasses = Math.abs(activeCarClasses.length - this.filters.carClasses.length)
            this.timerId = window.setTimeout(this.hideTooltip, 5000)
            this.showTooltip = true
            this.filters.carClasses = activeCarClasses
          }
        }
      }
    },

    computed: {
      ...mapGetters(["maxDate"]),

      hasProcessingReference() {
        return this.processingReferenceIds.includes(this.selectedReferenceId)
      },

      maxSelectCountCarClass() {
        return 10
      },

      carClasses({ isShared }) {
        return isShared ? this.sharedCarClasses : this.shopCarClasses
      },

      carClassesWithSuffix({ carClasses, chosenOtaIds, isShared }) {
        return carClasses.map(carClass => {
          const needToSetInventory = carClass.need_to_set_inventory

          const otaData = chosenOtaIds.map(otaId => carClass.ota_data[otaId])

          const inactiveOta = some(otaData, { inactive_ota: true })
          const inactiveSourceOrNotMatched = some(
            otaData,
            ({
              inactive_source_car_class,
              inactive_source_shop,
              not_matched_car_class,
              not_matched_shop,
              matchings_wrong
            }) =>
              inactive_source_car_class ||
              inactive_source_shop ||
              not_matched_car_class ||
              not_matched_shop ||
              matchings_wrong
          )

          if (needToSetInventory || inactiveOta || inactiveSourceOrNotMatched) {
            let tooltipText = ""
            switch (true) {
              case inactiveOta:
                tooltipText = this.$t("inventory_management.ota_account_disabled_tooltip")
                break
              case inactiveSourceOrNotMatched:
                tooltipText = isShared
                  ? this.$t("inventory_management.shared_inventory.improper_matching_tooltip")
                  : this.$t("inventory_management.improper_matching_tooltip")
                break
              case needToSetInventory:
                tooltipText = this.getText("need_set_inventory")
                break
            }
            return {
              ...carClass,
              icon: "exclamation-triangle",
              tooltipText
            }
          } else {
            return carClass
          }
        })
      },

      carClassesLoading({ isShared }) {
        return isShared ? this.sharedCarClassesLoading : this.shopCarClassesLoading
      },

      isUpdateDisabled({ hasInProgressExports, hasProcessingReference }) {
        const isOtasEmpty = !some(values(this.filters.otaStates))
        const isPeriodEmpty = isEmpty(this.filters.dates)
        const isReferenceEmpty = isEmpty(this.filters.reference)
        const isCarClassesEmpty = isEmpty(this.filters.carClasses)

        return (
          isOtasEmpty ||
          isPeriodEmpty ||
          isReferenceEmpty ||
          isCarClassesEmpty ||
          hasInProgressExports ||
          hasProcessingReference
        )
      },

      selectedReferenceId() {
        return this.filters.reference?.id
      },

      absentField() {
        const isOtasEmpty = !some(values(this.filters.otaStates))
        const isPeriodEmpty = isEmpty(this.filters.dates)
        const isReferenceEmpty = isEmpty(this.filters.reference)

        if (isReferenceEmpty) {
          return this.referenceType
        }

        if (isOtasEmpty) {
          return "ota"
        }

        if (isPeriodEmpty) {
          return "period"
        }

        return this.referenceType
      },

      isCarClassesDisabled() {
        const isPeriodEmpty = isEmpty(this.filters.dates)
        const isOtasEmpty = !some(values(this.filters.otaStates))
        const isReferenceEmpty = isEmpty(this.filters.reference)

        return isPeriodEmpty || isReferenceEmpty || isOtasEmpty
      },

      isShared() {
        return this.referenceType === "inventory_group"
      },

      chosenOtaIds() {
        return reduce(
          this.filters.otaStates,
          (res, value, key) => {
            if (value) {
              res.push(Number(key))
            }
            return res
          },
          []
        )
      },

      hasInProgressExports({ inProgressReferenceItemIds }) {
        if (!this.filters.reference?.id) return false

        return intersection(inProgressReferenceItemIds, [this.filters.reference.id]) > 0
      },

      inProgressReferenceItemIds() {
        return flatMap(this.exports, "reference_ids")
      }
    },

    methods: {
      isEmpty,

      hideTooltip() {
        this.timerId = null
        this.showTooltip = false
      },

      selectAll() {
        each(this.otaList, ({ id }) => {
          if (!this.isOtaDisabled(id)) {
            this.filters.otaStates[id] = true
          }
        })
      },

      clearSelection() {
        each(this.otaList, ({ id }) => {
          this.filters.otaStates[id] = false
        })
      },

      changeOtaState(id) {
        this.filters.otaStates[id] = !this.filters.otaStates[id]
      },

      changeDates(dates) {
        this.filters.dates = dates
      },

      handleSelect(type, event) {
        this.filters[type] = event
      },

      handleRemove(type, event) {
        this.filters[type] = event
      },

      disabledDate(date) {
        return date < startOfCurrentDateInTimeZone() || date > this.maxDate()
      },

      isItemDisabled({ immutable_ota_ids, need_to_set_inventory, ota_data }) {
        const otaData = this.chosenOtaIds.map(otaId => ota_data[otaId])
        const inactiveOta = some(otaData, { inactive_ota: true })
        const inactiveSourceOrNotMatched = some(
          otaData,
          ({
            inactive_source_car_class,
            inactive_source_shop,
            not_matched_car_class,
            not_matched_shop,
            matchings_wrong
          }) =>
            inactive_source_car_class ||
            inactive_source_shop ||
            not_matched_car_class ||
            not_matched_shop ||
            matchings_wrong
        )
        return (
          intersection(immutable_ota_ids, this.chosenOtaIds).length === 0 ||
          need_to_set_inventory ||
          inactiveOta ||
          inactiveSourceOrNotMatched
        )
      },

      getText(key, params = {}) {
        return this.$t(`inventory_management.inventory_import.resend_inventory_modal.${key}`, params)
      },

      fetchCarClasses() {
        const {
          filters: {
            reference: { id }
          }
        } = this
        const period = this.filters.dates.map(date => format(date, DATE_FNS_DATE_FORMAT))
        if (this.isShared) {
          return this.fetchSharedCarClasses({
            inventory_group_id: id,
            period,
            ota_ids: this.chosenOtaIds,
            serializer_view: SERIALIZER_VIEW_RESEND_MODAL
          })
        } else {
          return this.fetchShopCarClasses({
            pagination: { _disabled: true },
            shop_id: id,
            synced_only: true,
            shops_matched_only: true,
            period,
            ota_ids: this.chosenOtaIds,
            serializer_view: SERIALIZER_VIEW_RESEND_MODAL
          })
        }
      },

      cancel() {
        this.$emit("close")
      },

      update() {
        this.resendAction({ ...this.filters, chosenOtaIds: this.chosenOtaIds })
        this.$emit("close")
      },

      isOtaDisabled(otaId) {
        return !find(this.otaAccounts, { ota: { id: otaId }, active: true })
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .resend-inventory
    display: flex
    width: 888px

  .otas
    display: flex
    flex-direction: column
    width: 200px
    border-right: 1px solid $border-element-color
    padding-right: 24px

    &__title
      margin-bottom: 8px

    &__list
      max-height: 327px
      overflow-y: auto
      &::-webkit-scrollbar
        width: 4px

      &::-webkit-scrollbar-thumb
        background-color: #c1c1c1
        border-radius: 100px

    &__buttons
      display: flex
      align-items: center
      justify-content: space-between
      margin-bottom: 8px

      ::v-deep
        .app-button
          background-color: $default-white
          color: $default-purple
          border: 1px solid $default-purple
          font-size: 0.8rem
          font-weight: normal
          height: 27px
          padding: 4px 8px

    .ota
      display: flex
      align-items: center
      padding: 10px 9px
      border-radius: 4px
      &:not(:last-child)
        margin-bottom: 4px


      &:hover
        background-color: #e5e7fc8a

      &__name
        margin-left: 10px

      &__selected
        background-color: $default-purple-light

  .filters
    padding-left: 32px
    width: calc( 100% - 200px )
    position: relative

    .filter-title
      font-size: 13px
      font-weight: 400
      line-height: 19.2px
      margin-bottom: 4px
      color: #323c47bd

    .constraint
      display: flex

      ::v-deep
        svg
          margin-right: 5px
          color: $default-gray-medium
          fill: $default-gray-medium

    .car-class-label
      display: flex
      justify-content: space-between

    .datepicker-wrapper,
    .reference
      margin-bottom: 32px

    .car-class
      margin-bottom: 32px

    .buttons
      display: flex
      justify-content: flex-end

      button:first-child
        margin-right: 16px

      button:last-child
        background-color: $default-purple
        color: $default-white

      ::v-deep
        .app-button
          background-color: $default-white
          color: $default-purple
          border: 1px solid $default-purple
          font-size: 0.8rem
          font-weight: normal
          height: 34px
          width: 160px
          position: relative

    ::v-deep
      .app-select
        padding: 0

      .datepicker
        width: 100%
        padding-right: 0
        .mx-input
          height: 34px

    .car-class-tooltip
      display: flex
      align-items: center
      padding: 6px 12px
      color: #786D44
      background-color: #f9d71c26
      width: 100%
      margin-bottom: 90px
      border-radius: 5px
      opacity: 0
      transition: opacity 1s ease

      &_visible
        opacity: 1


      ::v-deep
        svg
          margin-right: 12px
          color: #786D44
          fill: #786D44

  .update-tooltip-container
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%

  .update-tooltip
    ::v-deep
      .tooltip-inner
        max-width: 600px
</style>
