<script>
  // store modules
  import shopMatchingModule from "@/config/store/matching/shop"
  import carClassMatchingModule from "@/config/store/matching/car_class"
  import inventoryManagementModule from "@/config/store/inventory_management"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"

  // misc
  import { appDebounce } from "@/helpers/common"

  const REQUEST_OPTIONS = {
    pagination: { _disabled: true },
    matched_only: true,
    synced_only: true
  }

  const shopsMixin = withStoreModule(shopMatchingModule, {
    name: "shopMatchingImport",
    readers: { shopsItems: "items", shopsLoading: "loading" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const carClassMixin = withStoreModule(carClassMatchingModule, {
    name: "carClassMatchingImport",
    readers: { carClassesItems: "items", carClassesLoading: "loading" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const inventoryManagementMixin = withStoreModule(inventoryManagementModule, {
    name: "inventoryManagement",
    actions: {
      importInventoryAvailable: "IMPORT_INVENTORY_AVAILABLE",
      importInventoryAction: "IMPORT_INVENTORY"
    }
  })

  export default {
    components: {
      ImportInventoryModal: () => import("@/components/InventoryManagement/ImportInventoryModal"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    mixins: [shopsMixin, carClassMixin, inventoryManagementMixin],

    mounted() {
      this.fetchShops(REQUEST_OPTIONS)
      this.fetchCarClasses({ ...REQUEST_OPTIONS, shops_matched_only: true })
    },

    data() {
      return {
        importAvailable: false,
        importInventoryAvailableLoading: false
      }
    },

    watch: {
      importFilters() {
        if (!this.isInvalidSelection) {
          this.importAvailable = false
          this.importInventoryAvailableLoading = true
          this.debouncedFetchImportInventoryAvailable()
        }
      }
    },

    methods: {
      debouncedFetchImportInventoryAvailable: appDebounce(function() {
        this.fetchImportInventoryAvailable()
      }),

      async fetchImportInventoryAvailable() {
        this.importAvailable = await this.importInventoryAvailable(this.importFilters)
        this.importInventoryAvailableLoading = false
      }
    }
  }
</script>
